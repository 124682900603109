import { ContactDetails } from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { ServiceData, SlotService } from '../../../utils/state/types';
import { OnError } from '../../types';
import { Membership } from '@wix/ambassador-totals-calculator/http';
import {
  ApiChannelType,
  CreateCheckoutResponse,
  CreateCheckoutRequest,
  FullAddressContactDetails,
  Platform_commonApiAddress,
  Checkout as EcomCheckoutServer,
} from '@wix/ambassador-checkout/http';
import { ExperimentsConsts } from '../../../consts/experiments';
import type { Experiments } from '@wix/yoshi-flow-editor';
import { mapContactDetails } from '../mapContactDetails/mapContactDetails';
import { BOOKINGS_APP_DEF_ID } from '../../../utils/flow-api-adapter/consts';
import { mapCheckoutBookingError } from '../../../utils/errors/errors';
import { getServiceSlotIdentifier, mapToArray } from '../../../utils';
import { FormApiContext, withErrorBoundary } from '../utils';
import { CouponDetails } from '../../../types/coupons';

export type GeneralBookingCheckoutFlowArgs = {
  serviceData: ServiceData;
  bookingIds: { [key: string]: string };
  bookingId?: string | null;
  appliedCoupon?: CouponDetails;
  contactDetails: ContactDetails;
  selectedMembership?: Membership;
  onError?: OnError;
  isCart?: boolean;
  country: string;
  couponCheckboxChecked?: boolean;
};

export async function generalBookingCheckoutFlow({
  authorization,
  reportError,
  ecomCheckoutServer,
  experiments,
  serviceData,
  bookingIds,
  appliedCoupon,
  contactDetails,
  onError,
  isCart,
  country,
  couponCheckboxChecked,
}: GeneralBookingCheckoutFlowArgs & FormApiContext) {
  if (Object.keys(bookingIds).length) {
    const { data: createCheckoutResponse, error: createCheckoutError } =
      await withErrorBoundary(
        {
          fn: () =>
            createCheckout(
              experiments,
              authorization,
              ecomCheckoutServer,
              serviceData,
              bookingIds,
              appliedCoupon,
              contactDetails,
              country,
            ),
          mapError: (e) => ({
            error: mapCheckoutBookingError(e?.response),
            shouldReport: true,
          }),
          fallback: {},
        },
        reportError,
      );

    if (createCheckoutError) {
      onError?.(createCheckoutError);
    }

    if (
      shouldNotGoThroughCheckout({
        experiments,
        createCheckoutResponse,
        isCart,
        couponCheckboxChecked,
      })
    ) {
      const {
        data: createOrderResponse,
        error: createOrderError,
        message: errorMessage,
      } = await withErrorBoundary(
        {
          fn: () =>
            createOrder(
              authorization,
              ecomCheckoutServer,
              createCheckoutResponse,
            ),
          mapError: (e) => ({
            error: mapCheckoutBookingError(e?.response),
            shouldReport: true,
          }),
          fallback: {},
        },
        reportError,
      );

      if (createOrderError) {
        onError?.(createOrderError, errorMessage);
      }

      return {
        createCheckoutResponse: createOrderResponse,
        bookingIds,
      };
    } else {
      return {
        createCheckoutResponse,
        bookingIds,
      };
    }
  }
  return {
    bookingIds,
    createCheckoutResponse: {},
  };
}

function createCheckout(
  experiments: Experiments,
  authorization: string,
  ecomCheckoutServer: ReturnType<typeof EcomCheckoutServer>,
  serviceData: ServiceData,
  bookingIds: { [key: string]: string },
  appliedCoupon: CouponDetails | undefined,
  contactDetails: ContactDetails,
  country: string,
) {
  const isSendAddressToEcomCheckoutAndCartEnabled = experiments.enabled(
    ExperimentsConsts.SendAddressToEcomCheckoutAndCart,
  );

  const createCheckoutRequest: CreateCheckoutRequest = {
    channelType: ApiChannelType.WEB,
    couponCode: appliedCoupon?.couponCode,
    lineItems: [],
    checkoutInfo: {
      billingInfo: {
        contactDetails: mapContactDetails({
          experiments,
          contactDetails,
        }) as FullAddressContactDetails,
        ...(isSendAddressToEcomCheckoutAndCartEnabled &&
        contactDetails.fullAddress &&
        country
          ? {
              address: {
                ...(contactDetails.fullAddress as Platform_commonApiAddress),
                country,
              },
            }
          : {}),
      },
      buyerInfo: {
        email: contactDetails.email!,
        ...(contactDetails.contactId
          ? { contactId: contactDetails.contactId as string }
          : {}),
      },
      membershipOptions: {
        selectedMemberships: {
          memberships: [],
        },
      },
    },
  };

  mapToArray<SlotService>(serviceData.slotServices).forEach((slotService) => {
    createCheckoutRequest.lineItems?.push({
      quantity: 1,
      id: slotService.nestedSlot.lineItemId,
      catalogReference: {
        catalogItemId:
          bookingIds[getServiceSlotIdentifier(slotService.nestedSlot)],
        appId: BOOKINGS_APP_DEF_ID,
      },
    });
    const selectedMembership =
      slotService.memberships?.eligibleMemberships?.find(
        (membership) => membership?.id === slotService.selectedPaymentOption.id,
      );

    if (selectedMembership) {
      createCheckoutRequest.checkoutInfo?.membershipOptions?.selectedMemberships?.memberships?.push(
        {
          id: selectedMembership.id,
          appId: selectedMembership.appId,
          lineItemIds: [slotService.nestedSlot.lineItemId],
        },
      );
    }
  });

  return ecomCheckoutServer
    .CheckoutService()({
      Authorization: authorization,
    })
    .createCheckout(createCheckoutRequest);
}

function isFreeOrPricePlanCheckoutFlow(
  createCheckoutResponse: CreateCheckoutResponse,
) {
  const payNowAmount = createCheckoutResponse?.checkout?.payNow?.total?.amount;
  const payLaterAmount =
    createCheckoutResponse?.checkout?.payLater?.total?.amount;
  return (
    payNowAmount &&
    Number(payNowAmount) === 0 &&
    payLaterAmount &&
    Number(payLaterAmount) === 0
  );
}

function isOfflineCheckoutFlow(createCheckoutResponse: CreateCheckoutResponse) {
  const payNowAmount = createCheckoutResponse?.checkout?.payNow?.total?.amount;
  return payNowAmount && Number(payNowAmount) === 0;
}

function createOrder(
  authorization: string,
  ecomCheckoutServer: ReturnType<typeof EcomCheckoutServer>,
  createCheckoutResponse: CreateCheckoutResponse,
) {
  return ecomCheckoutServer
    .CheckoutService()({
      Authorization: authorization,
    })
    .createOrder({
      id: createCheckoutResponse?.checkout?.id,
    });
}

function shouldNotGoThroughCheckout({
  experiments,
  isCart,
  couponCheckboxChecked,
  createCheckoutResponse,
}: {
  experiments: Experiments;
  createCheckoutResponse: CreateCheckoutResponse;
  isCart?: boolean;
  couponCheckboxChecked?: boolean;
}) {
  const isHideCouponInFormPageEnabled = experiments.enabled(
    ExperimentsConsts.HideCouponInFormPage,
  );

  return isHideCouponInFormPageEnabled || isCart
    ? isFreeOrPricePlanCheckoutFlow(createCheckoutResponse) ||
        (!couponCheckboxChecked &&
          isOfflineCheckoutFlow(createCheckoutResponse))
    : isOfflineCheckoutFlow(createCheckoutResponse);
}
