import { PlanWithDescription } from '../../../types/types';
import { FormApiContext } from '../utils';
import type {
  PublicPlan,
  QueryPublicPlansResponse,
} from '@wix/ambassador-pricing-plans-v2-plan/types';
import { queryPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { IFlowApiAdapter } from '../../../utils/flow-api-adapter/types';
import { ExperimentsConsts } from '../../../consts/experiments';

export const getPublicPlans = async ({
  httpClient,
  wixSdkAdapter,
  experiments,
}: FormApiContext): Promise<PlanWithDescription[] | undefined> => {
  try {
    let queryPlansResponse = (
      await httpClient.request<QueryPublicPlansResponse>(queryPublicPlans({}))
    )?.data;

    const allPlans = queryPlansResponse.plans || [];
    while (
      allPlans.length < queryPlansResponse.pagingMetadata?.total! &&
      queryPlansResponse.pagingMetadata?.count! > 0
    ) {
      queryPlansResponse = (
        await httpClient.request<QueryPublicPlansResponse>(
          queryPublicPlans({
            query: {
              paging: {
                offset:
                  queryPlansResponse.pagingMetadata?.offset! +
                  queryPlansResponse.pagingMetadata?.count!,
              },
            },
          }),
        )
      )?.data;
      allPlans.push(...(queryPlansResponse.plans || []));
    }

    return Promise.all(
      allPlans.map(async (plan) => {
        const { price, cycle, duration, freeTrial, additionalFees } =
          await getTranslatedPricingDetails(wixSdkAdapter, plan);

        const formattedAdditionalFees =
          additionalFees?.length !== 0
            ? ' + ' +
              additionalFees
                .map(({ amount, name }) => `${amount} ${name}`)
                .join(', ')
            : '';

        const formattedPrice =
          [price, cycle].filter((x) => x).join(',') + formattedAdditionalFees;

        return {
          ...plan,
          description: [formattedPrice, cycle, duration, freeTrial]
            .filter((x) => x)
            .join(', '),
        } as PlanWithDescription;
      }),
    );
  } catch (err: any) {
    if (experiments.enabled(ExperimentsConsts.BenfitsApiResiliance)) {
      console.error('getPublicPlans Failed: ', err);
      return undefined;
    } else {
      // Handling a case where pricing plans app is not installed in headless mode
      if (
        err.response.data.message === 'Pricing Plans instanceId not in context'
      ) {
        return undefined;
      }
      throw err;
    }
  }
};

const getTranslatedPricingDetails = async (
  wixSdkAdapter: IFlowApiAdapter,
  publicPlan: PublicPlan,
) => {
  const pricingPlansApi = await wixSdkAdapter.getPricingPlansApi();
  return pricingPlansApi.getTranslatedPricingDetails(publicPlan);
};
